/* You can add global styles to this file, and also import other style files */


.mat-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: auto!important;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: -24px;
}

.mat-dialog-content {
  display: block;
  margin: -10px -24px!important;
  padding: 10px 24px!important;
  max-height: auto!important;
  overflow: auto;

}

.cdk-global-overlay-wrapper {
  pointer-events: auto !important;
  /* display: block !important; */
  /* position: relative !important; */
  overflow: auto !important;
  text-align: center !important;
}

.cdk-global-overlay-wrapper::before {
  /* content: ''; */
  display: inline-block !important;
  height: 100% !important;
  white-space: nowrap !important;
}

.cdk-overlay-pane {
  display: inline-block !important;
/* position: relative !important; */
  text-align: left !important;
  white-space: normal !important;
  margin-top:50px !important;
}

.no-content-text h6{
text-align: center;
}

